.leaveRemaining {
    text-align: center;
    font-size: 18px!important;
}
.leaveRemaining .leave {
    margin-top: 15px;
    margin-bottom: 15px;
}
.leaveRemaining .ch {
    margin-bottom: 15px;
}
.leaveRemaining h3 {
    font-weight: bold;
}
.leaveForm {
    text-align: center;
    font-weight: bold;
}
.leaveForm h3 {
    font-weight: bold;
}
.DayPicker {
    font-size: 14px!important;
}
.DayPickerInput input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
}
.leaveForm2 {
    margin-top: 20px!important;
}
.leaveForm2 .form-group select {
    width: 50%;
}
.leaveForm2 .submitButton {
    text-align: center;
    margin-top: 30px;
}
.leaveForm2 .submitButton button {
    margin-right: auto;
    margin-left: auto;
    font-size: 12px;
    background-color: #1a546f;
}
.leaveForm2 .leaveBalance input, .leaveForm2 .willDeduct input {
    width: 25%;
}
.leaveForm2 .willDeduct .warning {
    border-color: red;
    border-width: 1.5px;
}
.leaveForm2 .select {
    margin-top: 6px;
    margin-bottom: 26px;
}
.leaveHistory {
    height: 350px;
}
.leaveHistory h3 {
    font-weight: bold;
    text-align: center;
}
.leaveHistory .table-responsive {
    margin-top: 12px;
}
.leaveHistory .table-responsive th {
    font-weight: bold;
}
.leaveHistory .noRecordFound {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
}
/* .leaveForm2 .leaveTypeGroup {
    margin-bottom: 10px!important;
}
.leaveForm2 .startDateGroup {
    margin-top: 29px!important;
}
.leaveForm2 .leaveTypeGroup .control-label {
    margin-bottom: 10px!important;
} 
.leaveForm2 .choiceHolidayGroup {
    margin-top: 24px!important;
}
.leaveForm2 .choiceHolidayGroup .control-label {
    margin-bottom: 10px!important;
}
.leaveForm2 .leaveBalance {
    margin-top: 22px!important;
}
@media only screen and (min-width: 768px) {
    .leaveForm2 .leaveTypeGroup {
        margin-bottom: 0px;
    }
    .leaveForm2 .startDateGroup {
        margin-top: 0px;
    }
    .leaveForm2 .leaveTypeGroup .control-label {
        margin-bottom: 0px;
    } 
} */