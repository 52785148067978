.table-responsive>.fixed-column {
    position: absolute;
    display: inline-block;
    width: auto;
    border-right: 1px solid #ddd;
    background-color: #fff;
}
.table-responsive {
    font-size: 14px;
}
.table-responsive thead th {
    font-size: 14px!important;
    padding: 8px!important;
}
.table-responsive td {
    padding: 8px!important;
}
.userListMain .titleText {
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 30px;
    /* margin-right: auto; */
    /* margin-left: auto; */
    font-weight: bold;
}
.title-div{
    display: flex;
    justify-content: space-between;
}
.userListMain h3 {
    font-size: 18px;
    text-align: center;
}
.userListMain .taskForDay, .additionalNote {
    white-space: pre-line;
}
.updateForm{
    display: flex;
    background-color: #fff;
    padding: 16px;
    flex-direction: column;
    min-width: 40vw;
}
.field{
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
}
.label{
    font-size: 18px;
    line-height: 1;
    color: #000;
    text-align: start;
}
.field-input{
    border: 1px solid ;
    padding: 12px 12px;
    border-radius: 0;
    transition: border-radius 1s;
}
.field-input:focus{
    border: 1px solid !important;
    border-radius: 18px;
}
.title-div button {    
    font-size: 12px;
    background-color: #1a546f !important;
    width: 13%;
    height: 56px;
}
.addUsersMain .modal-footer button {
    font-size: 12px;
}

@media(min-width:768px) {
    .userListMain .table-responsive>.fixed-column {
        display: none;
    }
    .userListMain .table-responsive {
        font-size: 16px!important;
    }
    .userListMain .table-responsive thead th {
        font-size: 16px!important;
    }
    .userListMain .titleText {
        font-size: 18px;
    }
}