/* =================================== */
/*  Menu Styles
/* =================================== */
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.dropbtn {
  font-weight: bold;
}
.dropbtn .fa-angle-down {
  margin-left: 8px;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

/* .dropdown-content a:hover {background-color: #ddd;} */

.dropdown:hover .dropdown-content {display: block;}

/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */
.main-menu {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 0px;
    margin-right: 0px;
    padding-right: 0;
    margin-left: 5px;
    display: flex;
    max-width: 100%!important;
  }
  .main-menu .align-items-center {
    width: 126%;
  }
  .main-menu .align-items-right {
    float: right;
    width: 22%;
    margin-top: 10px;
    margin-right: 10px;
  }
  .main-menu .align-items-right .childDiv {
    display: flex;
  }
  .main-menu .align-items-right .childDiv .profileImg img {
    border-radius: 50%;
  }
  .main-menu .align-items-right .dropBtn {
    font-weight: bold;
  }
  .main-menu .align-items-right .dropBtn .fa-angle-down {
    margin-left: 5px;
  }
  .main-menu .align-items-right .dropDownContain {
    display: none!important;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 96px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: 10px;
    right: 0;
    margin-right: 47px;
    color: black;
    padding: 10px;
  }
  .main-menu .align-items-right .dropDownContain:hover {
    display: block!important;
  }
  @media (min-width: 992px) {
    .main-menu {
        margin-left: 50px;
    }
  }
  
  #header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 997;
    background: #fff;
  }

  #header #logo img {
    height: 70px;
  }
  
  #header.header-scrolled {
    transition: all 0.5s;
    box-shadow: -21.213px 21.213px 30px 0px rgba(158, 158, 158, 0.3);
  }
  
  #header.header-scrolled .main-menu {
    border-color: transparent;
  }
  
  @media (max-width: 673px) {
    #logo {
      margin-left: 20px;
    }
  }
  
  .nav-menu li:hover > a, .menu-active, .parent-active > .sf-with-ul {
    color: #8490ff !important;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Nav Menu Essentials */
  .nav-menu, .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .nav-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    right: 0;
    z-index: 99;
  }
  
  .nav-menu li {
    position: relative;
    white-space: nowrap;
  }
  
  .nav-menu > li {
    float: left;
  }
  
  .nav-menu li:hover > ul,
  .nav-menu li.sfHover > ul {
    display: block;
  }
  
  .nav-menu ul ul {
    top: 0;
    right: 100%;
  }
  
  .nav-menu ul li {
    min-width: 180px;
  }

  .nav-menu .menu-has-children ul {
    margin-top: 0;
  }
  
  /* Nav Menu Arrows */
  .sf-arrows .sf-with-ul {
    padding-right: 30px;
  }
  
  .sf-arrows .sf-with-ul:after {
    content: "\f107";
    position: absolute;
    right: 15px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
  }
  
  .sf-arrows ul .sf-with-ul:after {
    content: "\f105";
  }
  
  /* Nav Meu Container */
  @media (max-width: 768px) {
    #nav-menu-container {
      display: none;
    }
  }
  
  /* Nav Meu Styling */
  .nav-menu a {
    padding: 0 8px 0px 8px;
    text-decoration: none;
    display: inline-block;
    color: #222;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    outline: none;
  }
  
  .nav-menu li:hover > a {
    color: #8490ff;
  }
  
  .nav-menu > li {
    margin-left: 10px;
  }
  
  .nav-menu ul {
    margin: 22px 0 0 0;
    padding: 10px;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    background: #fff;
  }
  
  .nav-menu ul li {
    transition: 0.3s;
  }
  
  .nav-menu ul li a {
    padding: 5px 10px;
    color: #333;
    transition: 0.3s;
    display: block;
    font-size: 12px;
    text-transform: none;
  }
  
  .nav-menu ul li:hover > a {
    color: #8490ff;
  }
  
  .nav-menu ul ul {
    margin-right: 10px;
    margin-top: 0;
  }

  #nav-menu-container {
    margin-left: 32px;
  }
  
  #nav-menu-container .nav-menu li a {
    font-weight: bold;
  }
  
  /* Mobile Nav Toggle */
  #mobile-nav-toggle {
    position: fixed;
    right: 15px;
    z-index: 999;
    top: 16px;
    border: 0;
    background: none;
    font-size: 24px;
    display: none;
    transition: all 0.4s;
    outline: none;
    cursor: pointer;
  }
  
  #mobile-nav-toggle i {
    color: #222;
    font-weight: 900;
  }
  
  @media (max-width: 991px) {
    #mobile-nav-toggle {
      display: inline;
    }
    #nav-menu-container {
      display: none;
    }
  }
  
  /* Mobile Nav Styling */
  #mobile-nav {
    position: fixed;
    top: 0;
    padding-top: 18px;
    bottom: 0;
    z-index: 998;
    /* background: rgba(0, 0, 0, 0.8); */
    background: #1a546f;
    left: -260px;
    width: 260px;
    overflow-y: auto;
    transition: 0.4s;
  }
  
  #mobile-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  #mobile-nav ul li {
    position: relative;
  }
  
  #mobile-nav ul li a {
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    overflow: hidden;
    padding: 10px 22px 10px 15px;
    position: relative;
    text-decoration: none;
    width: 100%;
    display: block;
    outline: none;
    font-weight: 400;
  }
  
  #mobile-nav ul li a:hover {
    color: #fff;
  }
  
  #mobile-nav ul li li {
    padding-left: 30px;
  }
  
  #mobile-nav ul .menu-has-children i {
    position: absolute;
    right: 0;
    z-index: 99;
    padding: 15px;
    cursor: pointer;
    color: #fff;
  }
  
  #mobile-nav ul .menu-has-children i.fa-chevron-up {
    color: #8490ff;
  }
  
  #mobile-nav ul .menu-has-children li a {
    text-transform: none;
  }
  
  #mobile-nav ul .menu-item-active {
    color: #8490ff;
  }
  
  #mobile-body-overly {
    width: 100%;
    height: 100%;
    z-index: 997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    display: none;
  }
  
  /* Mobile Nav body classes */
  body.mobile-nav-active {
    overflow: hidden;
  }
  
  body.mobile-nav-active #mobile-nav {
    left: 0;
  }
  
  body.mobile-nav-active #mobile-nav-toggle {
    color: #fff;
  }