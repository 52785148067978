/* Dropdown container */
.dropdown-container {
  margin: 10px;
  width: 100%;
  max-width: 350px;  /* Max width for the select box */
  position: relative;
}

.dropdown-container .css-1dyz3mf {
  max-height: 80px;
  overflow-y: auto;
}
